import $ from 'jquery';

$(function () {
	$(window).on('scroll', function () {
		// 画面スクロールの位置を取得
		var scroll = $(window).scrollTop();

		// スクロール位置が200pxを超えると追従ヘッダーを表示
		if (scroll > 50) {
			$('.c-header1').addClass('is-show');
		} else {
			$('.c-header1').removeClass('is-show');
		}
	});
});
